import { ValidationError, useForm } from "@formspree/react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function ContactFormHome({ formPropertyName }) {
	const [state, handleSubmit] = useForm("xvoypqyd");

	const formGroupStyles = "mb-3 mb-xl-4";
	const formInputStyles = "p-xl-4 p-3 ps-xl-5 ps-3  bottom-border-blue";

	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Row>
						<Col lg={6}>
							<Form.Group className={`${formGroupStyles}`} controlId="name">
								<Form.Control
									name="name"
									placeholder="Name"
									type="text"
									className={`${formInputStyles}`}
								/>
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
						</Col>
						<Col lg={6}>
							<Form.Group className={`${formGroupStyles}`} controlId="company">
								<Form.Control
									type="text"
									name="company"
									placeholder="Company"
									className={`${formInputStyles}`}
								/>
							</Form.Group>
							<ValidationError
								prefix="Company"
								field="company"
								errors={state.errors}
							/>
						</Col>
					</Row>

					<Form.Group className={`${formGroupStyles}`} controlId="email">
						<Form.Control
							name="email"
							type="email"
							placeholder="Email Address"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />

					<Form.Group className={`${formGroupStyles}`} controlId="subject">
						<Form.Control
							name="subject"
							type="text"
							placeholder="Subject"
							value={formPropertyName ? formPropertyName : ""}
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="Subject"
						field="subject"
						errors={state.errors}
					/>

					<Form.Group className={`${formGroupStyles}`} controlId="message">
						<Form.Control
							name="message"
							placeholder="Message"
							as="textarea"
							rows={3}
							className={`${formInputStyles}`}
						/>
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
					<div className="text-end">
						<Button
							size="small"
							className="btn btn-darker-blue text-white mt-3 px-6 py-3  w-100 w-md-auto"
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							SUBMIT
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Row>
				<Col lg={6}>
					<Form.Group className={`${formGroupStyles}`} controlId="name">
						<Form.Control
							name="name"
							placeholder="Name"
							type="text"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
				<Col lg={6}>
					<Form.Group className={`${formGroupStyles}`} controlId="company">
						<Form.Control
							type="text"
							name="company"
							placeholder="Company"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="Company"
						field="company"
						errors={state.errors}
					/>
				</Col>
			</Row>

			<Form.Group className={`${formGroupStyles}`} controlId="email">
				<Form.Control
					name="email"
					type="email"
					placeholder="Email Address"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className={`${formGroupStyles}`} controlId="subject">
				<Form.Control
					name="subject"
					type="text"
					placeholder="Subject"
					value={formPropertyName ? formPropertyName : ""}
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Subject" field="subject" errors={state.errors} />

			<Form.Group className={`${formGroupStyles}`} controlId="message">
				<Form.Control
					name="message"
					placeholder="Message"
					as="textarea"
					rows={3}
					className={`${formInputStyles}`}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
			<div className="text-end">
				<Button
					size="small"
					className="btn btn-darker-blue text-white mt-3 px-6 py-3  w-100 w-md-auto"
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					SUBMIT
				</Button>
			</div>
		</Form>
	);
}
export default ContactFormHome;
