import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import CartContext from "../context/CartContext";
import ContactFormHome from "../components/contact-form-home";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const { cart } = React.useContext(CartContext);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Contact Us | Your Desk Workplace Solutions"
				description="Find your perfect office solution with the UK's most trusted brokerage."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Us | Your Desk Workplace Solutions",
					description:
						"Find your perfect office solution with the UK's most trusted brokerage.",
					images: [
						{
							url: `${data.heroInterior?.localFile.publicURL}`,
							width: `${data.heroInterior?.localFile.childImageSharp.original.width}`,
							height: `${data.heroInterior?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroInterior?.altText}`,
						},
					],
				}}
			/>
			<section>
				<Container>
					<div className="py-3 py-md-5 px-3 px-md-5 mt-3 mb-5 ">
						<Row>
							<Col>
								<h3>Get in touch</h3>
							</Col>
						</Row>
						<Row>
							<Col lg={4}>
								<p>
									Fill in your contact details below and we'll put you in touch
									with one of our experts.
								</p>
							</Col>
						</Row>
						<ContactFormHome />
					</div>
				</Container>
			</section>
		</Layout>
	);
};

export default ContactUsPage;
